import React from 'react'
import styled from '@emotion/styled'
import { Global } from '@emotion/react'
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'

import 'typeface-lato'
import { useStaticQuery } from 'gatsby'
// import { SEO } from 'gatsby-plugin-seo'
import Logo3d from './Logo3d'
import BackgroundImg from '../images/bg-brown.jpg'


const GLOBAL_STYLES = `
body {
  font-family: 'lato',sans-serif;
  background: #000;
}
`

const Content = styled.div`
  position: absolute;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Background = styled.div`
  position: absolute;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  // background-size: cover;
  filter: invert(100%);
  opacity: 0.3;
  
  animation: zoom-anim 50s infinite;
  animation-timing-function: ease-in-out;
  @keyframes zoom-anim {
    0% {
      background-size: 200vh;
    }
    45% {
      background-size: 350vh;
    }
    55% {
      background-size: 350vh;
    }
    100% {
      background-size: 200vh;
    }
  }
`

const LogoLink = styled.a`
  // width: 450px;
  max-width: 70%;
`

const Away = styled.a`
  color: #1D81C2;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  & > div {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 25vw;
    background: rgba(255,255,255,0.3);
    box-shadow: 0px 0px 20px white;
    padding: 1vh 2vw;
    box-sizing: border-box;
    border-radius: 2%;
    filter: invert(100%);
    & > *+* {
      margin-top: 2em;
    }
  }
`


const Desc = styled.div`
  max-width: 80%;
  text-align: center;
  font-size: 11pt;
  // color: #607d8b;
  color: #000;
  line-height: 1.3em;
  // font-style: italic;
  // font-weight: bold;   
`

const ContactLink = styled(Away)`
  font-size: 10pt;
    text-align: center;
`

export default function Home() {

  const { site: { siteMetadata } } = useStaticQuery(query)

  return (
    <>
      <Global styles={GLOBAL_STYLES}/>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={siteMetadata.title}
        meta={[{
          name: siteMetadata.title,
          content: siteMetadata.description,
          keywords: siteMetadata.keywords,
        }]}
      />

      {/*<SEO*/}
      {/*  title="GeoVisually"*/}
      {/*  description="Geographic data visualization consultancy. We develop interactive data visualizations and maps."*/}
      {/*  pagePath="/"*/}
      {/*/>*/}

      <Background/>
      <Content>
        <LogoLink href="http://ilya.boyandin.me/">
          <Logo3d interactive={false} />
          {/*<img*/}
          {/*  width={790}*/}
          {/*  src={LogoImg} alt="GeoVisually"*/}
          {/*/>*/}
        </LogoLink>
      </Content>

      <Footer>
        <div>
          <Desc>
            interactive maps and geographic&nbsp;data&nbsp;visualization
            <br/>
            consultancy founded by <Away href="http://ilya.boyandin.me/">Ilya&nbsp;Boyandin</Away>.
          </Desc>

          <ContactLink href="http://ilya.boyandin.me/">
            © GeoVisually GmbH, {new Date().getFullYear()}
          </ContactLink>
        </div>
      </Footer>
    </>)
}


const query = graphql`
  query SiteMetadata {
    site {
      siteMetadata {
        title
        description
        siteUrl
        keywords
      }
    }
  }
`
